
<template>
  <div class="m-0 md:m-4">

    <!-- Cliente solicitou, visto que está ocorrendo muita reclamação -->
    <!--
    <div class="flex justify-items-center" v-if="partialScore">
      <vs-alert
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4">
          {{ $t('a-correcao-ainda-nao-foi-liberada-pelo-professor-todas-as-pontuacoes-ainda-sao-parciais-e-nao-definitiva') }}
        </vs-alert>
    </div>
    -->
    <div class="flex justify-items-center" v-if="answersNotFound">
      <vs-alert
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4">
          {{ $t('voce-ainda-nao-respondeu-este-questionario') }}
        </vs-alert>
    </div>

    <div class="flex justify-items-center" v-if="!showResults">
      <vs-alert
        :title="$t('alerta')"
        color="rgb(231, 154, 23)"
        active="true"
        class="mt-5 text-warning mb-4 h-auto">
          {{ $t('aguarde-a-liberacao-das-notas-pelo-professor-ainda-nao-e-possivel-visualizar-as-respostas') }}
        </vs-alert>
    </div>

    <div :hidden="answerListEmpty" v-if="showResults">
      <vx-card :title="$t('respostas')" class="mb-4" collapse-action>
        <div class="vx-row w-full">
          <div class="vx-col sm:w-4/12 w-4/12">
            <b>{{ $t('participante') }}</b>
          </div>
          <div class="vx-col sm:w-5/12 w-5/12">
            <b>{{ $t('Questionnaire') }}</b>
          </div>
        </div>

        <div class="vx-row w-full">
          <div class="vx-col sm:w-4/12 w-4/12">
              {{userName}}
              <span class="text-sm">{{userEmail}}</span>
          </div>
          <div class="vx-col sm:w-5/12 w-5/12">{{questionnaireName}}</div>
        </div>
        <div class="vx-row mt-3">
          <div class="vx-col w-full">
            <vs-button color="primary" class="float-right" type="border" size="small" @click="cancel" v-if="!showBackButton">{{ $t('common.back') }}</vs-button>
          </div>
        </div>
      </vx-card>
    </div>

    <div v-if="showResults">
      <vx-card
        :title="$t('data-entrega-answer-finished_at', [$utils.format.date(_.get(answer, 'finished_at'))])"
        v-for="answer of answerList"
        :key="answer"
        class="mb-4"
        collapse-action
        >

        <div class="grid grid-cols-2">
          <div v-if="!hasLeveledScore(answer)">
            <div class="flex flex-wrap float-left print:hidden">
              <div class="flex flex-wrap float-left mt-2 mb-2">
                <b>{{ $t('pontuacao') }}: &nbsp;&nbsp;</b>
                {{ formatFloat(answer.total_score) }} / {{ formatFloat(answer.max_total_score) }} {{ scorePercentage(answer) }}
              </div>
            </div>
          </div>
          <div class="row-start-2 mt-2" v-else>
            <label class="font-bold">{{$t('pontuacao') }}:</label> <span>{{ formatFloat(answer.leveled_score)}}</span>
          </div>
          <div class="row-start-3 mt-2" v-if="hasAverageScore">
            <label class="font-bold">{{ $t('media-geral') }} </label> <span>{{ formatFloat(scoreResume.average)}}</span>
          </div>
          <div class="row-start-4 mt-2" v-if="showSecondChanceResume(answer)">
            <label class="font-bold">{{ $t('second_chance') }}: </label> <br>
            <span :class="getClassLabelSecondChance(answer)">{{_.get(answer, 'second_chance.amount')}} / {{_.get(answer, 'second_chance.parameters.total')}} Tentativas</span>
          </div>

          <div class="row-start-5 mt-2" v-if="ranking.place" v-permission="'questionnaires.ranking.place.show'">
            <div class="flex sm:flex-row flex-col sm:gap-2 sm:my-0 my-2 sm:items-center">
              <label class="font-bold">{{ $t('colocacao') }} </label>
              <span>{{ $t('ranking-place-o-lugar', [ranking.place]) }}</span>
              <span
                v-permission="'questionnaires.ranking.related.show'"
                class="text-primary hover:underline cursor-pointer text-sm"
                @click="ranking.show = true"
              >{{ $t('ver-posicao-geral') }}</span>
            </div>
          </div>
          <div class="grid grid-flow-row auto-rows-min float-right md:align-end row-span-3 justify-self-end" v-if="!hideQuestions">
            <div class="text-center py-1 px-2">
              <b>{{ $t('modo-visualizacao-0') }}</b>
            </div>
            <div class="flex items-center justify-center">
              <div class="grid grid-flow-row auto-rows-min md:grid-flow-col md:auto-cols-max md:w-auto">
                <div class="text-center py-1 px-2">{{ $t('detalhado') }}</div>
                <div class="text-center py-1 ml-2 items-center justify-center flex">
                  <vs-switch v-model="simpleView" />
                </div>
                <div class="text-center py-1 px-4">{{ $t('resumido') }}</div>
              </div>
            </div>
          </div>
          <div class="pt-2 flex flex-col gap-2 lg:flex-row lg:gap-4" v-html-safe="calcQuestionsInfo(answer)"></div>
        </div>

        <vs-row class="w-full flex gap-2 justify-end pt-5 pb-5 print:hidden">
          <vs-button type="border" icon="print" class="" @click="print(answer)" v-permission="'questionnaire_answers.print'" v-if="!hideQuestions">{{ $t('imprimir') }}</vs-button>
          <vs-button type="border" icon="repeat" @click="secondChanceAnswerUser(answer)" v-if="showSecondChanceButton(answer)"
            class="text-success"
            v-tooltip.top="{
                  content: $t('second_chance_user_description'),
                  delay: { show: 1000, hide: 100 }
                }"><div class="inline pl-1 font-semibold">{{ $t('action.second_chance') }}</div>
          </vs-button>
          <vs-button class="float-right" color="primary" type="border" @click="cancel" v-if="!showBackButton">{{ $t('common.back') }}</vs-button>
        </vs-row>

        <div v-if="!hideQuestions">
          <AnswerResult
            v-if="!simpleView"
            :answers="answer.answers"
            :questionnaireData="results ? results['questionnaireData'] : ''"
            :hideQuestionScore="hideQuestionScore"
            :viewAnswers="viewAnswers"/>
        </div>

        <vs-table :data="answer.answers" v-if="simpleView">
          <template slot="thead">
            <vs-th width="50%">{{ $t('questao') }}</vs-th>
            <vs-th width="20%" v-if="!hideQuestionScore">{{ $t('points') }}</vs-th>
            <vs-th width="30%">{{ $t('comentario-do-professor') }}</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :class="getRowClass(data[i])" :state="correctionStatus(data[i])" :key="data[i]" v-for="(tr, i) in data">
              <vs-td>
                <div v-html-safe="shortTitle(data[i].title)" class="editor-content ck-content"></div>
              </vs-td>
              <vs-td v-if="!hideQuestionScore">{{score(data[i])}}</vs-td>
              <vs-td>
                <vs-button
                  type="filled"
                  color="success"
                  icon="speaker_notes"
                  class="m-1"
                  @click="showCommentSectionModal(data[i])"
                  v-tooltip.top="{
                    content: 'Abrir comentários',
                    delay: { show: 1000, hide: 100 }
                  }"
                ></vs-button>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>

    <div class="vx-row mb-12">
      <div class="vx-col w-full">
        <vs-button class="float-right mr-4" v-if="answersNotFound" @click="accessQuestionnaire"> {{ $t('responder-agora') }} </vs-button>
      </div>
    </div>

    <vs-popup :title="$t('comments')" :active.sync="showCommentSection">
      <AnswerCommentSection
        ref="commentsection"
      ></AnswerCommentSection>
    </vs-popup>

    <questionnaire-ranking
      @closed="ranking.show = false"
      v-if="results && results.questionnaireId && $acl.hasPermission('questionnaires.ranking.related.show')"
      :id="results.questionnaireId"
      :showRanking="ranking.show"
    />

  </div>
</template>

<script>
import ContentQuestionnaireService from '@/services/api/ContentQuestionnaireService'
import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import AnswerResult from '@/views/apps/questionnaires/answers/AnswerResult.vue'
import AnswerCommentSection from '../answers/AnswerCommentSection'
import QuestionnaireRanking from '../ranking/QuestionnaireRanking.vue'
import { getQuestion } from '@/util/QuestionnaireUtil'

export default {
  components: {
    AnswerResult,
    AnswerCommentSection,
    QuestionnaireRanking
  },
  props: {
    short_url_id: {
      type: String,
      default: null,
    },
    answer_id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    questionnaireAnswerService: null,
    results: null,
    showResults: false,
    simpleView: false,
    showCommentSection: false,
    scoreResume: {average: 0, median: 0},
    ranking: {show: false, place: null},
    showBackButton: false
  }),
  watch: {
    results() {
      if (this.results && 'view_results' in this.results && 'score_released_at' in this.results) {
        this.showResults = !!(this.results.view_results || this.results.score_released_at)
      } else {
        this.showResults = false
      }
      console.log('this.showResults', this.showResults)
      this.answersNotFound = this.answerListEmpty && this.showResults
    },
    questionnaireName() {
      document.title = this.questionnaireName
    }
  },
  computed: {
    hasAverageScore() {
      if (this.scoreResume) {
        return this.scoreResume.average !== undefined || this.scoreResume.average !== null
      }
      return false
    },
    userName() {
      return this.$store.state.AppActiveUser.displayName
    },
    userEmail() {
      return this.$store.state.AppActiveUser.email
    },
    questionnaireName() {
      return this.results ? this.results['questionnaireName'] : ''
    },
    answerList() {
      return this.results ? this.results.answersList : []
    },
    answerListEmpty() {
      return this.answerList.length === 0
    },
    scoreReleasedAt() {
      return this.results && this.results['score_released_at'] ? this.results['score_released_at'] : '--'
    },
    partialScore() {
      return this.results && this.results['score_released_at'] === null && this.showResults
    },
    questionnaireFields() {
      return this._.get(this.results, 'questionnaireData.fields', [])
    },
    viewAnswers() {
      return this._.get(this.results, 'view_answers', false)
    }
  },
  methods: {
    print(answer) {
      const routeData = this.$router.resolve({
        name: 'questionnaire_answer_print',
        params: { questionnaire_short_url: this.short_url_id, answer_id: answer.answer_id },
      })
      window.open(routeData.href, '_blank')
    },
    getRowClass(data) {
      const base = ''
      const questionId = this._.get(data, 'question_id')

      if (questionId) {
        const question = getQuestion(questionId, this.questionnaireFields, {})
        const isInvalidated = this._.get(question, 'data.invalidated.justification', false)
        if (isInvalidated !== false) {
          return this.$t('base-invalidated-item-without-bg', [base])
        }
      }

      return base
    },
    getResults() {
      this.$vs.loading()
      if (this.answer_id !== null) {
        this.questionnaireAnswerService.results(this.short_url_id, this.answer_id).then(
          (response) => {
            const {scoreResume, ranking, hide_question_score, hide_questions, ...results} = response
            this.results = results
            this.scoreResume = scoreResume
            this.ranking.place = ranking
            this.hideQuestionScore = hide_question_score
            this.hideQuestions = hide_questions
            this.$vs.loading.close()
          },
          (error) => {
            console.error(error)
            this.$vs.loading.close()
          }
        )
      } else {
        this.questionnaireAnswerService.allResults(this.short_url_id).then(
          (response) => {
            const {scoreResume, ranking, hide_question_score, hide_questions,...results} = response
            this.results = results
            this.scoreResume = scoreResume
            this.ranking.place = ranking
            this.hideQuestionScore = hide_question_score
            this.hideQuestions = hide_questions
            this.$vs.loading.close()
          },
          (error) => {
            console.error(error)
            this.$vs.loading.close()
          }
        )
      }

    },
    accessQuestionnaire() {
      this.$router.push(`/form/${this.short_url_id}`)
    },
    correctionStatus(data) {
      return data.correction ? 'success' : data.correction === false ? 'danger' : null
    },
    shortTitle(title) {
      return this.truncate(this._.trim(this.html2text(title)), 50)
    },
    score(data) {
      return data.score !== null ? data.score : this.partialScore ? this.$t('aguardando-correcao') : '-'
    },
    scorePercentage(answer) {
      if (answer.max_total_score <= 0) return ''
      const percentage = Math.ceil((answer.total_score / answer.max_total_score) * 100)
      return `( ${percentage}% )`
    },
    cancel() {
      this.$router.push('/questionnaires/answers')
    },
    showCommentSectionModal(answerData) {
      this.$refs.commentsection.getData(answerData.answer_id, answerData.question_id)
      this.showCommentSection = true
    },
    calcQuestionsInfo(answer) {
      let corretQtd = 0
      let wrongQtd = 0
      let pendingQtd = 0
      const classes = 'class="sm:gap-2 flex flex-col sm:flex-row" '

      answer.answers.forEach(element => {
        if ('correction' in element) {
          if (element.correction) {
            corretQtd++
          } else {
            wrongQtd++
          }
        } else {
          pendingQtd++
        }
      })
      let result  = `<div ${classes}><b>${this.$t('corretas')}: </b>${corretQtd}</div>
                     <div ${classes}><b>${this.$t('incorretas')}: </b>${wrongQtd}</div>`

      if(pendingQtd > 0){
        result += `<div ${classes}><b>${this.$t('aguardando-correcao-0')}: </b>${pendingQtd}</div>`
      }

      return result
    },
    hasLeveledScore(answer) {
      return (answer.leveled_score !== undefined  && (answer.leveled_score != null && answer.leveled_score !== ''))
    },
    showSecondChanceButton(answer){
     let secondChance = this._.get(answer, 'second_chance.enable', false)
     return secondChance
    },
    secondChanceAnswerUser(answer) {
      this.$vs.loading()
      this.questionnaireAnswerService.secondChanceAnswerUser(answer.answer_id).then(
        response => {
          this.$vs.loading.close()
          this.accessQuestionnaire()
        },
        error => {
          this.showErrors(this.$validator, error, 15000)
        }
      ).finally(() => {
        this.$vs.loading.close()
      })
    },
    notifySecondChanceError(){
      this.$vs.dialog({
          icon: 'icon-alert-circle',
          color: 'success',
          title: this.$t('atencao'),
          text: this.$t('document_person.token_expiries'),
          acceptText: this.$t('ok-0'),
          accept: () => {this.closePopup(false)}
        })
    },
    showSecondChanceResume(answer){
     let secondChance = this._.get(answer, 'second_chance', false)
     return secondChance
    },
    getClassLabelSecondChance(answer) {
      let amount = this._.get(answer, 'second_chance.amount', 0)
      let total = this._.get(answer, 'second_chance.parameters.total', 0)
      return parseInt(amount) >= parseInt(total) ? 'text-danger' : 'text-success'
    }
  },
  mounted() {
    if (window.location.href.indexOf('/full') >= 0) {
      this.showBackButton = true
    }
  },
  beforeMount() {
    this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
    this.getResults()
  }
}
</script>

<style>
.b-transparent:hover {
  background: transparent !important;
}
</style>
