var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "m-0 md:m-4" },
    [
      _vm.answersNotFound
        ? _c(
            "div",
            { staticClass: "flex justify-items-center" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-5 text-warning mb-4",
                  attrs: {
                    title: _vm.$t("alerta"),
                    color: "rgb(231, 154, 23)",
                    active: "true",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("voce-ainda-nao-respondeu-este-questionario")
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.showResults
        ? _c(
            "div",
            { staticClass: "flex justify-items-center" },
            [
              _c(
                "vs-alert",
                {
                  staticClass: "mt-5 text-warning mb-4 h-auto",
                  attrs: {
                    title: _vm.$t("alerta"),
                    color: "rgb(231, 154, 23)",
                    active: "true",
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "aguarde-a-liberacao-das-notas-pelo-professor-ainda-nao-e-possivel-visualizar-as-respostas"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showResults
        ? _c(
            "div",
            { attrs: { hidden: _vm.answerListEmpty } },
            [
              _c(
                "vx-card",
                {
                  staticClass: "mb-4",
                  attrs: { title: _vm.$t("respostas"), "collapse-action": "" },
                },
                [
                  _c("div", { staticClass: "vx-row w-full" }, [
                    _c("div", { staticClass: "vx-col sm:w-4/12 w-4/12" }, [
                      _c("b", [_vm._v(_vm._s(_vm.$t("participante")))]),
                    ]),
                    _c("div", { staticClass: "vx-col sm:w-5/12 w-5/12" }, [
                      _c("b", [_vm._v(_vm._s(_vm.$t("Questionnaire")))]),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-row w-full" }, [
                    _c("div", { staticClass: "vx-col sm:w-4/12 w-4/12" }, [
                      _vm._v(" " + _vm._s(_vm.userName) + " "),
                      _c("span", { staticClass: "text-sm" }, [
                        _vm._v(_vm._s(_vm.userEmail)),
                      ]),
                    ]),
                    _c("div", { staticClass: "vx-col sm:w-5/12 w-5/12" }, [
                      _vm._v(_vm._s(_vm.questionnaireName)),
                    ]),
                  ]),
                  _c("div", { staticClass: "vx-row mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full" },
                      [
                        !_vm.showBackButton
                          ? _c(
                              "vs-button",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  color: "primary",
                                  type: "border",
                                  size: "small",
                                },
                                on: { click: _vm.cancel },
                              },
                              [_vm._v(_vm._s(_vm.$t("common.back")))]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showResults
        ? _c(
            "div",
            _vm._l(_vm.answerList, function (answer) {
              return _c(
                "vx-card",
                {
                  key: answer,
                  staticClass: "mb-4",
                  attrs: {
                    title: _vm.$t("data-entrega-answer-finished_at", [
                      _vm.$utils.format.date(_vm._.get(answer, "finished_at")),
                    ]),
                    "collapse-action": "",
                  },
                },
                [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    !_vm.hasLeveledScore(answer)
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex flex-wrap float-left print:hidden",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap float-left mt-2 mb-2",
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm.$t("pontuacao")) + ":   "
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatFloat(answer.total_score)
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm.formatFloat(answer.max_total_score)
                                      ) +
                                      " " +
                                      _vm._s(_vm.scorePercentage(answer)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ])
                      : _c("div", { staticClass: "row-start-2 mt-2" }, [
                          _c("label", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("pontuacao")) + ":"),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatFloat(answer.leveled_score))
                            ),
                          ]),
                        ]),
                    _vm.hasAverageScore
                      ? _c("div", { staticClass: "row-start-3 mt-2" }, [
                          _c("label", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("media-geral")) + " "),
                          ]),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatFloat(_vm.scoreResume.average))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.showSecondChanceResume(answer)
                      ? _c("div", { staticClass: "row-start-4 mt-2" }, [
                          _c("label", { staticClass: "font-bold" }, [
                            _vm._v(_vm._s(_vm.$t("second_chance")) + ": "),
                          ]),
                          _vm._v(" "),
                          _c("br"),
                          _c(
                            "span",
                            { class: _vm.getClassLabelSecondChance(answer) },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._.get(answer, "second_chance.amount")
                                ) +
                                  " / " +
                                  _vm._s(
                                    _vm._.get(
                                      answer,
                                      "second_chance.parameters.total"
                                    )
                                  ) +
                                  " Tentativas"
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm.ranking.place
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "questionnaires.ranking.place.show",
                                expression:
                                  "'questionnaires.ranking.place.show'",
                              },
                            ],
                            staticClass: "row-start-5 mt-2",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex sm:flex-row flex-col sm:gap-2 sm:my-0 my-2 sm:items-center",
                              },
                              [
                                _c("label", { staticClass: "font-bold" }, [
                                  _vm._v(_vm._s(_vm.$t("colocacao")) + " "),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("ranking-place-o-lugar", [
                                        _vm.ranking.place,
                                      ])
                                    )
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permission",
                                        rawName: "v-permission",
                                        value:
                                          "questionnaires.ranking.related.show",
                                        expression:
                                          "'questionnaires.ranking.related.show'",
                                      },
                                    ],
                                    staticClass:
                                      "text-primary hover:underline cursor-pointer text-sm",
                                    on: {
                                      click: function ($event) {
                                        _vm.ranking.show = true
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("ver-posicao-geral")))]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    !_vm.hideQuestions
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "grid grid-flow-row auto-rows-min float-right md:align-end row-span-3 justify-self-end",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "text-center py-1 px-2" },
                              [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.$t("modo-visualizacao-0"))),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex items-center justify-center",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-flow-row auto-rows-min md:grid-flow-col md:auto-cols-max md:w-auto",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "text-center py-1 px-2" },
                                      [_vm._v(_vm._s(_vm.$t("detalhado")))]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center py-1 ml-2 items-center justify-center flex",
                                      },
                                      [
                                        _c("vs-switch", {
                                          model: {
                                            value: _vm.simpleView,
                                            callback: function ($$v) {
                                              _vm.simpleView = $$v
                                            },
                                            expression: "simpleView",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "text-center py-1 px-4" },
                                      [_vm._v(_vm._s(_vm.$t("resumido")))]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", {
                      directives: [
                        {
                          name: "html-safe",
                          rawName: "v-html-safe",
                          value: _vm.calcQuestionsInfo(answer),
                          expression: "calcQuestionsInfo(answer)",
                        },
                      ],
                      staticClass:
                        "pt-2 flex flex-col gap-2 lg:flex-row lg:gap-4",
                    }),
                  ]),
                  _c(
                    "vs-row",
                    {
                      staticClass:
                        "w-full flex gap-2 justify-end pt-5 pb-5 print:hidden",
                    },
                    [
                      !_vm.hideQuestions
                        ? _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: "questionnaire_answers.print",
                                  expression: "'questionnaire_answers.print'",
                                },
                              ],
                              attrs: { type: "border", icon: "print" },
                              on: {
                                click: function ($event) {
                                  return _vm.print(answer)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("imprimir")))]
                          )
                        : _vm._e(),
                      _vm.showSecondChanceButton(answer)
                        ? _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    content: _vm.$t(
                                      "second_chance_user_description"
                                    ),
                                    delay: { show: 1000, hide: 100 },
                                  },
                                  expression:
                                    "{\n                content: $t('second_chance_user_description'),\n                delay: { show: 1000, hide: 100 }\n              }",
                                  modifiers: { top: true },
                                },
                              ],
                              staticClass: "text-success",
                              attrs: { type: "border", icon: "repeat" },
                              on: {
                                click: function ($event) {
                                  return _vm.secondChanceAnswerUser(answer)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "inline pl-1 font-semibold" },
                                [_vm._v(_vm._s(_vm.$t("action.second_chance")))]
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.showBackButton
                        ? _c(
                            "vs-button",
                            {
                              staticClass: "float-right",
                              attrs: { color: "primary", type: "border" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(_vm._s(_vm.$t("common.back")))]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.hideQuestions
                    ? _c(
                        "div",
                        [
                          !_vm.simpleView
                            ? _c("AnswerResult", {
                                attrs: {
                                  answers: answer.answers,
                                  questionnaireData: _vm.results
                                    ? _vm.results["questionnaireData"]
                                    : "",
                                  hideQuestionScore: _vm.hideQuestionScore,
                                  viewAnswers: _vm.viewAnswers,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.simpleView
                    ? _c(
                        "vs-table",
                        {
                          attrs: { data: answer.answers },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var data = ref.data
                                  return _vm._l(data, function (tr, i) {
                                    return _c(
                                      "vs-tr",
                                      {
                                        key: data[i],
                                        class: _vm.getRowClass(data[i]),
                                        attrs: {
                                          state: _vm.correctionStatus(data[i]),
                                        },
                                      },
                                      [
                                        _c("vs-td", [
                                          _c("div", {
                                            directives: [
                                              {
                                                name: "html-safe",
                                                rawName: "v-html-safe",
                                                value: _vm.shortTitle(
                                                  data[i].title
                                                ),
                                                expression:
                                                  "shortTitle(data[i].title)",
                                              },
                                            ],
                                            staticClass:
                                              "editor-content ck-content",
                                          }),
                                        ]),
                                        !_vm.hideQuestionScore
                                          ? _c("vs-td", [
                                              _vm._v(
                                                _vm._s(_vm.score(data[i]))
                                              ),
                                            ])
                                          : _vm._e(),
                                        _c(
                                          "vs-td",
                                          [
                                            _c("vs-button", {
                                              directives: [
                                                {
                                                  name: "tooltip",
                                                  rawName: "v-tooltip.top",
                                                  value: {
                                                    content:
                                                      "Abrir comentários",
                                                    delay: {
                                                      show: 1000,
                                                      hide: 100,
                                                    },
                                                  },
                                                  expression:
                                                    "{\n                  content: 'Abrir comentários',\n                  delay: { show: 1000, hide: 100 }\n                }",
                                                  modifiers: { top: true },
                                                },
                                              ],
                                              staticClass: "m-1",
                                              attrs: {
                                                type: "filled",
                                                color: "success",
                                                icon: "speaker_notes",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showCommentSectionModal(
                                                    data[i]
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  })
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "template",
                            { slot: "thead" },
                            [
                              _c("vs-th", { attrs: { width: "50%" } }, [
                                _vm._v(_vm._s(_vm.$t("questao"))),
                              ]),
                              !_vm.hideQuestionScore
                                ? _c("vs-th", { attrs: { width: "20%" } }, [
                                    _vm._v(_vm._s(_vm.$t("points"))),
                                  ])
                                : _vm._e(),
                              _c("vs-th", { attrs: { width: "30%" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("comentario-do-professor"))
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "vx-row mb-12" }, [
        _c(
          "div",
          { staticClass: "vx-col w-full" },
          [
            _vm.answersNotFound
              ? _c(
                  "vs-button",
                  {
                    staticClass: "float-right mr-4",
                    on: { click: _vm.accessQuestionnaire },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("responder-agora")) + " ")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "vs-popup",
        {
          attrs: { title: _vm.$t("comments"), active: _vm.showCommentSection },
          on: {
            "update:active": function ($event) {
              _vm.showCommentSection = $event
            },
          },
        },
        [_c("AnswerCommentSection", { ref: "commentsection" })],
        1
      ),
      _vm.results &&
      _vm.results.questionnaireId &&
      _vm.$acl.hasPermission("questionnaires.ranking.related.show")
        ? _c("questionnaire-ranking", {
            attrs: {
              id: _vm.results.questionnaireId,
              showRanking: _vm.ranking.show,
            },
            on: {
              closed: function ($event) {
                _vm.ranking.show = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }